import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { FormikHelpers } from "formik";
import Typography from "@material-ui/core/Typography";
import useStyles from "./useStyles";
import register from "../../helpers/APICalls/register";
import AuthSideBanner from "../../components/AuthSideBanner/AuthSideBanner";
import SignUpForm from "./SignUpForm/SignUpForm";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { useAuth } from "../../context/useAuthContext";
import { useSnackBar } from "../../context/useSnackbarContext";

export interface handleSubmit {}

export default function Register() {
	const classes = useStyles();
	const { updateLoginContext } = useAuth();
	const { updateSnackBarMessage } = useSnackBar();

	const handleSubmit = (
		{
			username,
			email,
			password,
		}: { email: string; password: string; username: string },
		{
			setSubmitting,
		}: FormikHelpers<{ email: string; password: string; username: string }>
	) => {
		register(username, email, password).then((data) => {
			if (data.error) {
				console.error({ error: data.error.message });
				setSubmitting(false);
				updateSnackBarMessage(data.error.message);
			} else if (data.success) {
				updateLoginContext(data.success);
			} else {
				// should not get here from backend but this catch is for an unknown issue
				console.error({ data });

				setSubmitting(false);
				updateSnackBarMessage(
					"An unexpected error occurred. Please try again"
				);
			}
		});
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<AuthSideBanner />
			<Grid
				item
				xs={12}
				sm={8}
				md={7}
				elevation={6}
				component={Paper}
				square
			>
				<Box className={classes.authWrapper}>
					<AuthHeader
						linkTo="/login"
						asideText="Already have an account?"
						btnText="Login"
					/>
					<Box width="100%" maxWidth={450} p={3} alignSelf="center">
						<Grid container>
							<Grid item xs>
								<Typography
									className={classes.welcome}
									component="h1"
									variant="h5"
								>
									Create an account
								</Typography>
							</Grid>
						</Grid>
						<SignUpForm handleSubmit={handleSubmit} />
					</Box>
					<Box p={1} alignSelf="center" />
				</Box>
			</Grid>
		</Grid>
	);
}
